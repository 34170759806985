



















































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";
import { required } from "vuelidate/lib/validators";
import Mentor from "@/views/components/inputs/mentor.vue";
import FileUploader from "@/views/components/inputs/fileUploader.vue";

const uniqueMentor = (param: string) => (value = "") => value != param;

@Component({
  components: {
    FileUploader,
    Mentor,
    UserAuto: UserAutoComplete
  }
})
export default class SetMentorsByAdmin extends Vue {
  private paymentTypes = Enum.TypePayment;
  private form: Record<string, any> = {};
  private errors: Record<string, Array<string>> = {};
  private loading = true;
  private onlinePaid = false;

  mounted() {
    if (this.$route.params.id) {
      this.getEvent(this.$route.params.id);
    } else this.loading = false;
  }

  setMentor2(value: string) {
    this.form.mentor2 = value;
    (this.$v.form.mentor2 as any).$touch();
  }

  validations() {
    return {
      form: {
        mentor1: {
          required
        },
        mentor2: {
          required,
          uniqueMentor: uniqueMentor(this.form.mentor1)
        }
      }
    };
  }

  public async submit(): Promise<void> {
    try {
      const form = { ...this.form };
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new EventApi().setMentorsByAdmin(
        form,
        this.$route.params.id
      );
      if (!this.$route.params.id) {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.form = {};
      }
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      await this.$router.back();
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getEvent(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().view(Number(id));
      const item = {
        ...res.data.data
      };
      item.type = item.type_original;
      item.mentor1 = item.mentors.filter(
        (x: any) => x.pivot.staff_id == 1
      )[0]?.id;
      item.mentor2 = item.mentors.filter(
        (x: any) => x.pivot.staff_id == 6
      )[0]?.id;
      item.summery = "";
      item.eventPaymentType = (Enum.eventPaymentType as any)[
        item.manual_payment
      ];
      this.form = item;
      this.loading = false;
      const paid = item.transaction.filter((x: any) => x.status);
      if (paid.length > 0) {
        this.onlinePaid = true;
      }
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
